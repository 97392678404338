<template>
  <div class="page">
    <Login ref="login" :callback="init" />
    <Navbar title="公招报名" type="SMP" />
    <div class="top">
      <div class="name">{{ gift.name }}</div>
      <van-row class="item">
        <van-col :span="6" class="left">
          <van-tag mark :color="COLOR_M">有效期限</van-tag></van-col
        >
        <van-col :span="18" class="right"
          >{{ gift.startTime }} 到 {{ gift.endTime }}</van-col
        >
      </van-row>
      <van-row class="item">
        <van-col :span="6" class="left">
          <van-tag mark :color="COLOR_M">礼包描述</van-tag></van-col
        >
        <van-col :span="18" class="right">{{ gift.description }}</van-col>
      </van-row>
    </div>

    <div class="amount">
      <div class="title">内容明细</div>
      <div class="item" v-for="paid in gift.paids" :key="paid.code">
        <div class="left">{{ paid.name }}</div>
        <div class="right color-s2">{{ paid.count }}次</div>
      </div>
      <div class="button">
        <van-button
          type="primary"
          :color="COLOR_M"
          class="btn"
          @click="receiveGift"
          icon="certificate"
          size="small"
          block
          >立即领取</van-button
        >
      </div>
    </div>
    <Loading :show="loadingShow" />
    <Share ref="share" />
    <LogVisit ref="logVisit" module-type="CNT" module="CNT_INDEX" />
  </div>
</template>
<script>
import Index from './Index.js'
export default {
  ...Index
}
</script>
<style lang="less" scoped>
@import './Index.less';
</style>
