import { Tag, Icon } from 'vant'
import Login from '../../intercept/Login.vue'
import Navbar from '../../common/Navbar.vue'
import Share from '../../common/Share.vue'
import Loading from '../../common/Loading.vue'
import LogVisit from '../../common/LogVisit.vue'
import '../../../../assets/icon/font-icon.js'
export default {
    components: {
        Login: Login,
        Share: Share,
        Navbar: Navbar,
        Loading: Loading,
        LogVisit: LogVisit,
        [Tag.name]: Tag,
        [Icon.name]: Icon
    },
    data () {
        return {
            key: '',
            gift: {}
        }
    },
    mounted () {
        var query = this.$route.query
        this.label = query.label
        this.$refs.login.validate()
    },
    methods: {
        init () {
            this.retrieveGift()
        },
        async retrieveGift () {
            var pd = { appKey: 'WPP_MOC', label: this.label }
            var { data: res } = await this.$http.post(this.UMS_URL + '/system/gift/retrieveLabelGift', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.gift = res.data
            }
        },
        async receiveGift () {
            var userCode = window.sessionStorage.getItem(this.SESSION_USER)
            var pd = {
                userCode: userCode,
                giftCode: this.gift.code
            }
            const { data: res } = await this.$http.post(this.UMS_URL + '/customer/user/receiveGift', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.$dialog.alert({
                    title: '提示信息',
                    message: '领取成功'
                }).then(() => {
                    this.$router.go(-1)
                })
            } else {
                this.$dialog.alert({
                    title: '提示信息',
                    message: res.msg
                })
            }
            this.loadingShow = false
        }
    }
}
